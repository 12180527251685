import correlationSheet from "../../../assets/data/correlationSheet.xlsx";
import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, OutlinedInput, Button, Box, Typography, Snackbar, Alert } from '@mui/material';
import * as XLSX from 'xlsx';
import HeaderComponent from "../../../Components/mainComponents/HeaderComponent";
import { tablestyle } from "../../../assets/data/styles";

const CorrelationComp = () => {
    const [names, setNames] = useState([]);
    const [matrixData, setMatrixData] = useState([]);
    const [selectedOption, setSelectedOption] = useState([]);
    const [showMatrix, setShowMatrix] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const snackbarPosition = { vertical: "top", horizontal: "center" };

    useEffect(() => {
        const fetchExcelData = async () => {
            const response = await fetch(correlationSheet);
            const blob = await response.blob();
            const data = await blob.arrayBuffer();
            const workbook = XLSX.read(data);
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];

            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            const headerRow = jsonData[0];
            const rows = jsonData.slice(1);

            setNames(headerRow.slice(1));
            setMatrixData(rows);
        };

        fetchExcelData();
    }, []);

    const handleDropdownChange = (event) => {
        const { value } = event.target;
        if (value.length > 5) {
            setOpenSnackbar(true);
            setSnackbarMessage("You can only select up to 5 stocks.");
            return;
        }
        setSelectedOption(value);
    };

    const handleButtonClick = () => {
        setShowMatrix(true);
    };

    const handleClearClick = () => {
        setSelectedOption([]);
    };

    const getFilteredMatrixData = () => {
        const results = [];
        selectedOption.forEach((name) => {
            const rowIndex = matrixData.findIndex(row => row[0] === name);
            if (rowIndex !== -1) {
                const rowData = { 'Matrix Names': name };
                selectedOption.forEach(selectedName => {
                    const colIndex = names.indexOf(selectedName) + 1;
                    rowData[selectedName] = matrixData[rowIndex][colIndex];
                });
                results.push(rowData);
            }
        });
        return results;
    };

    return (
        <Box>
            <HeaderComponent />
            <Box p={2} >
                <Typography variant="h4" textAlign="center" gutterBottom>
                    Correlation Matrix
                </Typography>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "center", }}>
                    <FormControl sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "1rem" }}>
                        <Box>
                            <InputLabel id="demo-multiple-checkbox-label">Select Stocks</InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={selectedOption}
                                onChange={handleDropdownChange}
                                input={<OutlinedInput label="Tag" />}
                                renderValue={(selected) => selected.join(', ')}
                                sx={{ maxWidth: {md:"600px", xs:"350px"}, minWidth:"300px", overflow: 'hidden', }}
                            >
                                {names.map((name) => (
                                    <MenuItem key={name} value={name} >
                                        <Checkbox checked={selectedOption.indexOf(name) > -1} />
                                        <ListItemText primary={name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <Box sx={{display:'flex', gap:"1.5rem"}}>
                            <Button
                                variant="contained"
                                onClick={handleButtonClick}
                            >Go</Button>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={handleClearClick}
                            >Clear</Button>
                        </Box>
                        <div style={{
                            boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
                            padding:"0.5rem"
                        }}>
                            {showMatrix && selectedOption.length >= 2 && selectedOption.length <= 5 && (
                                <table style={tablestyle}>
                                    <thead>
                                        <tr>
                                            <th style={tablestyle}>Matrix</th>
                                            {selectedOption.map(name => <th style={{...tablestyle, maxWidth:"100px"}} key={name}>{name}</th>)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {getFilteredMatrixData().map((row, index) => (
                                            <tr key={index}>
                                                <td style={{...tablestyle, maxWidth:"100px", fontWeight:"bold"}}>{row['Matrix Names']}</td>
                                                {selectedOption.map(name => {
                                                    const value = row[name];
                                                    let bgColor;
                                                    
                                                    if (value >= 1) {
                                                        bgColor = 'rgb(100 191 124)';
                                                    } else if (value >= 0.6) {
                                                        bgColor = `rgba(100, 191, 124, ${(value - 0.5) / 0.5})`; 
                                                    } else if (value > 0.3) {
                                                        bgColor = '#fee082'; // Decreasing yellow
                                                    } else if (value >= -0.1) {
                                                        bgColor = `rgba(248, 107, 107, ${(0.3 - value) / 0.4})`; // Increasing red
                                                    } else {
                                                        bgColor = 'rgb(248 107 107)';
                                                    }

                                                    return (
                                                        <td style={{...tablestyle, maxWidth:"100px", backgroundColor: bgColor}} key={name}>
                                                            {value}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </FormControl>
                </Box>
            </Box>
            <Snackbar
                anchorOrigin={snackbarPosition}
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
            >
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default CorrelationComp;