import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel, Select, MenuItem, Box, Typography } from '@mui/material';
import HeaderComponent from '../../../Components/mainComponents/HeaderComponent';
import bgstrip from "../../../assets/images/bgstrip.jpg";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import HeightIcon from '@mui/icons-material/Height';

function FinancialMetrics() {
    const [headings, setHeadings] = useState([]);
    const [values, setValues] = useState([]);
    const [rnk, setRnk] = useState([]);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [duration, setDuration] = useState(1); // Default duration

    const fetchData = async (selectedDuration) => {
        try {
            const response1 = await fetch(
                "https://sheets.googleapis.com/v4/spreadsheets/1OZEF6yzibZjEl3xyP3D9jOuKih3oW9BJgNlQDsomX5Y/values/profit?alt=json&key=AIzaSyAq3ypn4xpDpaquusYVJ3e00OHhLnH7__k"
            );
            const data1 = await response1.json();
            const headings = data1.values[0].slice(1);
            const values = data1.values.slice(1);

            const response2 = await fetch(
                "https://sheets.googleapis.com/v4/spreadsheets/1lPjk0uEU2NGU8TybTq9qZ5a2rPNSAo_UTP3ojpcUW9g/values/heatccsv1?alt=json&key=AIzaSyAq3ypn4xpDpaquusYVJ3e00OHhLnH7__k"
            );
            const data2 = await response2.json();
            const sheet2 = data2.values.slice(1);
            const headings2 = data2.values[0].slice(1);

            let alldata = new Array(sheet2[0].length).fill(0);
            let allmonth = 0;
            const currentmonth = sheet2[5][0].split("-")[1];
            const currentyear = sheet2[5][0].split("-")[2];

            for (let i = 0; i < sheet2.length; i++) {
                if (
                    sheet2[i][0].split("-")[1] === currentmonth &&
                    sheet2[i][0].split("-")[2] === currentyear
                ) {
                    allmonth++;
                    for (let j = 1; j < sheet2[i].length; j++) {
                        alldata[j - 1] += +sheet2[i][j];
                    }
                }
            }

            const rankper = alldata.map((data) => (data / allmonth).toFixed(2));
            const rnk = headings.map((heading) => {
                const index = headings2.indexOf(heading);
                return index !== -1 ? rankper[index] : null;
            });

            setHeadings(headings);
            setValues(values);
            setRnk(rnk);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const renderSortIcon = (sortOrder) => {
        if (sortOrder === 'asc') {
            return <ArrowDropUpIcon />;
        } else if (sortOrder === 'desc') {
            return <ArrowDropDownIcon />;
        } else {
            return <HeightIcon />;
        }
    };

    const handleDurationChange = (event) => {
        const selectedDuration = event.target.value;
        setDuration(selectedDuration);
        fetchData(selectedDuration);
    };

    return (
        <Box>
            <HeaderComponent />
            <Typography sx={{
                backgroundImage: `url(${bgstrip})`,
                color: "white",
                paddingLeft: { xs: "1rem", md: "0" },
                paddingRight: { xs: "1rem", md: "0" },
                fontSize: { md: "2rem", xs: "1.5rem" }, textAlign: "center", fontWeight: "bold"
            }}>
                Returns, Risk, Return Risk Ratios, Value Index
            </Typography>
            <Box p={1} />
            <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column", gap: "1rem" }}>
                <Select
                    // labelId="demo-simple-select-label"
                    // id="demo-simple-select"
                    value={duration}
                    onChange={handleDurationChange}
                    displayEmpty
                    sx={{ width: "300px", color:"black" }}
                    label="Age"
                >
                    <MenuItem value="" disabled>
                        <em>Select a duration</em>
                    </MenuItem>
                    <MenuItem value="1">1 Year</MenuItem>
                    <MenuItem value="3">3 Years</MenuItem>
                    <MenuItem value="5">5 Years</MenuItem>
                    <MenuItem value="10">10 Years</MenuItem>
                </Select>
                <TableContainer component={Paper} >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Indices</TableCell>
                                <TableCell sortDirection={orderBy === 'return' ? order : false}>
                                    <TableSortLabel
                                        active={orderBy === 'return'}
                                        direction={orderBy === 'return' ? order : 'asc'}
                                        onClick={() => handleRequestSort('return')}
                                        IconComponent={() => renderSortIcon(orderBy === 'return' ? order : null)}
                                    >
                                        Return (%)
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={orderBy === 'risk' ? order : false}>
                                    <TableSortLabel
                                        active={orderBy === 'risk'}
                                        direction={orderBy === 'risk' ? order : 'asc'}
                                        onClick={() => handleRequestSort('risk')}
                                        IconComponent={() => renderSortIcon(orderBy === 'risk' ? order : null)}
                                    >
                                        Risk (%)
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={orderBy === 'rrRatio' ? order : false}>
                                    <TableSortLabel
                                        active={orderBy === 'rrRatio'}
                                        direction={orderBy === 'rrRatio' ? order : 'asc'}
                                        onClick={() => handleRequestSort('rrRatio')}
                                        IconComponent={() => renderSortIcon(orderBy === 'rrRatio' ? order : null)}
                                    >
                                        RR-ratio
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={orderBy === 'valueIndex' ? order : false}>
                                    <TableSortLabel
                                        active={orderBy === 'valueIndex'}
                                        direction={orderBy === 'valueIndex' ? order : 'asc'}
                                        onClick={() => handleRequestSort('valueIndex')}
                                        IconComponent={() => renderSortIcon(orderBy === 'valueIndex' ? order : null)}
                                    >
                                        ValueIndex
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {headings.map((heading, index) => (
                                <TableRow key={index}>
                                    <TableCell>{heading}</TableCell>
                                    <TableCell>{/* Calculate and display Return */}</TableCell>
                                    <TableCell>{/* Calculate and display Risk */}</TableCell>
                                    <TableCell>{/* Calculate and display RR-ratio */}</TableCell>
                                    <TableCell>{rnk[index]}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
}

export default FinancialMetrics;