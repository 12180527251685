import React, { useState, useEffect, useRef } from 'react';
import { Box, Select, MenuItem, FormControl, InputLabel, Button, Typography, Grid, Paper, } from '@mui/material';
import { Chart } from 'chart.js';
import * as echarts from 'echarts';
import HeaderComponent from '../../../Components/mainComponents/HeaderComponent';

const Portfolio = () => {
  const [categories, setCategories] = useState([]);
  const [names, setNames] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedScheme1, setSelectedScheme1] = useState('');
  const [selectedScheme2, setSelectedScheme2] = useState('');
  const chartContainerRef = useRef(null);
  const pieChartRef = useRef(null);
  const [pieChartInstance, setPieChartInstance] = useState(null);

  useEffect(() => {
    // Fetch category data
    fetch(
      'https://mfapi.advisorkhoj.com/getVSFintechAllMutualFundSchemes?key=7f08aea7-2bd6-4a6f-bdc4-f82f5c0088de'
    )
      .then((response) => response.json())
      .then((data) => {
        const categoriesSet = new Set(
          data.vsf_scheme_list.map(
            (scheme) => scheme.scheme_advisorkhoj_category
          )
        );
        setCategories(Array.from(categoriesSet));
      })
      .catch((error) => console.error('Error fetching categories:', error));
  }, []);

  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setSelectedCategory(category);

    // Fetch schemes for the selected category
    fetch(
      'https://mfapi.advisorkhoj.com/getVSFintechAllMutualFundSchemes?key=7f08aea7-2bd6-4a6f-bdc4-f82f5c0088de'
    )
      .then((response) => response.json())
      .then((data) => {
        const schemes = data.vsf_scheme_list
          .filter(
            (scheme) => scheme.scheme_advisorkhoj_category === category
          )
          .map((scheme) => scheme.scheme_amfi);
        setNames(schemes);
      })
      .catch((error) => console.error('Error fetching schemes:', error));
  };

  const handleSubmit = () => {
    if (!selectedCategory || !selectedScheme1 || !selectedScheme2) {
      alert('Please select all fields.');
      return;
    }

    fetch(
      `https://mfapi.advisorkhoj.com/getMutualfundPortfolioOverlap?key=7f08aea7-2bd6-4a6f-bdc4-f82f5c0088de&category=${selectedCategory}&scheme1=${selectedScheme1}&scheme2=${selectedScheme2}`
    )
      .then((response) => response.json())
      .then((data) => {
        // Update bar chart
        const chartInstance = echarts.init(chartContainerRef.current);
        chartInstance.setOption({
          tooltip: {
            trigger: 'axis',
            axisPointer: { type: 'shadow' },
          },
          grid: { left: '3%', right: '4%', bottom: '3%', containLabel: true },
          xAxis: {
            type: 'category',
            data: [
              'Fund1 not common with Fund2',
              'Fund1 common with Fund2',
              'Fund2 not common with Fund1',
              'Fund2 common with Fund1',
            ],
          },
          yAxis: { type: 'value' },
          series: [
            {
              name: 'Fund%',
              type: 'bar',
              barWidth: '30%',
              data: [
                data.scheme1_uncommon_stocks,
                data.scheme1_common_stocks,
                data.scheme2_uncommon_stocks,
                data.scheme2_common_stocks,
              ],
              itemStyle: {
                normal: {
                  color: (params) =>
                    ['#FFC107', '#4CAF50', '#FFC107', '#4CAF50'][
                      params.dataIndex
                    ],
                },
              },
            },
          ],
        });

        // Update pie chart
        if (pieChartInstance) {
          pieChartInstance.destroy();
        }
        const ctx = pieChartRef.current.getContext('2d');
        const newPieChartInstance = new Chart(ctx, {
          type: 'pie',
          data: {
            labels: ['Portfolio Overlap', 'Non-Overlap'],
            datasets: [
              {
                data: [
                  data.average_portfolio_overlap,
                  100 - data.average_portfolio_overlap,
                ],
                backgroundColor: ['#4CAF50', '#FFC107'],
              },
            ],
          },
          options: {
            plugins: { legend: { display: true, position: 'top' } },
            maintainAspectRatio: false,
          },
        });
        setPieChartInstance(newPieChartInstance);
      })
      .catch((error) => console.error('Error fetching overlap data:', error));
  };


  return (
    <Box>
      <HeaderComponent />
      <Box p={3}>
        <Typography variant="h4" textAlign="center" gutterBottom>
          Portfolio Comparison
        </Typography>

        <div className="table-container">
        <select
          onChange={handleCategoryChange}
          value={selectedCategory}
          defaultValue=""
        >
          <option value="" disabled>
            Choose Category
          </option>
          {categories.map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
        <select
          onChange={(e) => setSelectedScheme1(e.target.value)}
          value={selectedScheme1}
          defaultValue=""
        >
          <option value="" disabled>
            Choose Scheme 1
          </option>
          {names.map((name) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </select>
        <select
          onChange={(e) => setSelectedScheme2(e.target.value)}
          value={selectedScheme2}
          defaultValue=""
        >
          <option value="" disabled>
            Choose Scheme 2
          </option>
          {names.map((name) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </select>
        <button onClick={handleSubmit}>Submit</button>
      </div>
      <div ref={chartContainerRef} style={{ height: '400px' }}></div>
      <div>
        <canvas ref={pieChartRef} style={{ maxWidth: '200px' }}></canvas>
      </div>
      </Box>
    </Box>
  );
};

export default Portfolio;