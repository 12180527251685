import { Box, Button, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import vsfintechLogo from "../../assets/images/vsfintechLogo.png";
import { useNavigate, useLocation } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from "react";

const drawerWidth = 240;
const navItems = ['Codes', 'Subscribe','Mutual Funds', 'Login'];

const OutHeaderComp = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const nav = useNavigate();
  const location = useLocation();

  const onListItemButtonClick = (item) => {
    if (item === "Codes") {
      nav("/120/codes");
    } else if (item === "Mutual Funds") {
      nav("/120/mutual-funds");
    } else if (item === "Subscribe") {
      nav("/subscribe");
    } else if (item === "Login") {
      nav("/120/login");
    }
  }

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const getNavPath = (item) => {
    if (item === 'Codes') return '/120/codes';
    if (item === 'Mutual Funds') return '/120/mutual-funds';
    if (item === 'Login') return '/120/login';
    if (item === 'Subscribe') return '/subscribe';
    return '/404';
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Box
        component="img"
        sx={{
          height: 50,
          width: 130,
        }}
        alt="VSFINTECH"
        src={vsfintechLogo}
      />
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton
              sx={{
                textAlign: 'center',
                color: location.pathname === getNavPath(item) ? 'blue' : 'black'
              }}
              onClick={() => onListItemButtonClick(item)}
            >
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", pl: { xs: 2, sm: 3 }, pr: { xs: 2, sm: 4 } }}>
      <Box
        component="img"
        sx={{
          height: 50,
          width: 130,
        }}
        alt="VSFINTECH"
        src={vsfintechLogo}
      />
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        {navItems.map((item) => (
          <Button
            key={item}
            sx={{
              color: location.pathname === getNavPath(item) ? 'blue' : 'black'
            }}
            onClick={() => onListItemButtonClick(item)}
          >
            {item}
          </Button>
        ))}
      </Box>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ display: { sm: 'none' } }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  )
}
export default OutHeaderComp;